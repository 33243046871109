"use client";
import { createMakeStyles, TssCacheProvider } from "./makeStyles";
export { createMakeStyles, TssCacheProvider };
import { createWithStyles } from "./withStyles";
export { createWithStyles };
/** @see <https://docs.tss-react.dev/api/keyframes> */
export { keyframes } from "@emotion/react";
/** @see <https://docs.tss-react.dev/api/globalstyles> */
export { GlobalStyles } from "./GlobalStyles";
/** @see <https://docs.tss-react.dev/api/makestyles> */
export function createMakeAndWithStyles(params) {
    return {
        ...createMakeStyles(params),
        ...createWithStyles(params)
    };
}
