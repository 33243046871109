{
  "name": "@regscout/ui",
  "version": "1.13.2",
  "scripts": {
    "localdev": "dotenv -e .env.localdev -- npx parcel public/index.html -p 8080 --https",
    "remotedev": "dotenv -e .env.remotedev -- npx parcel public/index.html -p 8080 --https",
    "build-dev": "npx parcel build public/index.html --no-optimize",
    "build-prod": "npx parcel build public/index.html --no-source-maps"
  },
  "devDependencies": {
    "@apollo/client": "^3.11.8",
    "@babel/core": "^7.23.6",
    "@fontsource/oswald": "^4.5.10",
    "@fontsource/roboto": "^4.5.8",
    "@hookform/resolvers": "^2.9.11",
    "@mui/material": "^5.16.11",
    "@parcel/bundler-experimental": "^2.7.0",
    "@regscout/core": "1.13.2",
    "amplitude-js": "^8.21.8",
    "axios": "^1.7.7",
    "js-cookie": "^3.0.1",
    "jwt-decode": "^3.1.2",
    "logrocket": "^4.0.0",
    "logrocket-react": "^5.0.1",
    "parcel": "^2.8.3",
    "prop-types": "^15.8.1",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hook-form": "^7.43.1",
    "sha.js": "^2.4.11",
    "underscore": "^1.13.6",
    "yup": "^1.0.2",
    "yup-password": "^0.2.2"
  },
  "browserlist": ">1% and not dead",
  "author": "BreachRx",
  "description": "",
  "private": true
}