"use client";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";
import { objectFromEntries } from "./tools/polyfills/Object.fromEntries";
import { objectKeys } from "./tools/objectKeys";
import { createUseCssAndCx } from "./cssAndCx";
import { getDependencyArrayRef } from "./tools/getDependencyArrayRef";
import { typeGuard } from "./tools/typeGuard";
import { assert } from "./tools/assert";
import { mergeClasses } from "./mergeClasses";
import { createContext, useContext } from "react";
import { __unsafe_useEmotionCache as useContextualCache } from "@emotion/react";
let counter = 0;
export function createMakeStyles(params) {
    const { useTheme, cache: cacheProvidedAtInception } = params;
    function useCache() {
        const contextualCache = useContextualCache();
        const cacheExplicitlyProvidedForTss = useCacheProvidedByProvider();
        const cacheToBeUsed = cacheProvidedAtInception ??
            cacheExplicitlyProvidedForTss ??
            contextualCache;
        if (cacheToBeUsed === null) {
            throw new Error([
                "In order to get SSR working with tss-react you need to explicitly provide an Emotion cache.",
                "MUI users be aware: This is not an error strictly related to tss-react, with or without tss-react,",
                "MUI needs an Emotion cache to be provided for SSR to work.",
                "Here is the MUI documentation related to SSR setup: https://mui.com/material-ui/guides/server-rendering/",
                "TSS provides helper that makes the process of setting up SSR easier: https://docs.tss-react.dev/ssr"
            ].join("\n"));
        }
        return cacheToBeUsed;
    }
    const { useCssAndCx } = createUseCssAndCx({ useCache });
    /** returns useStyle. */
    function makeStyles(params) {
        const { name: nameOrWrappedName, uniqId = counter++ } = params ?? {};
        const name = typeof nameOrWrappedName !== "object"
            ? nameOrWrappedName
            : Object.keys(nameOrWrappedName)[0];
        return function (cssObjectByRuleNameOrGetCssObjectByRuleName) {
            const getCssObjectByRuleName = typeof cssObjectByRuleNameOrGetCssObjectByRuleName ===
                "function"
                ? cssObjectByRuleNameOrGetCssObjectByRuleName
                : () => cssObjectByRuleNameOrGetCssObjectByRuleName;
            return function useStyles(params, styleOverrides) {
                const theme = useTheme();
                const { css, cx } = useCssAndCx();
                const cache = useCache();
                let classes = useMemo(() => {
                    const refClassesCache = {};
                    const refClasses = typeof Proxy !== "undefined" &&
                        new Proxy({}, {
                            "get": (_target, propertyKey) => {
                                if (typeof propertyKey === "symbol") {
                                    assert(false);
                                }
                                return (refClassesCache[propertyKey] = `${cache.key}-${uniqId}${name !== undefined ? `-${name}` : ""}-${propertyKey}-ref`);
                            }
                        });
                    const cssObjectByRuleName = getCssObjectByRuleName(theme, params, refClasses || {});
                    const classes = objectFromEntries(objectKeys(cssObjectByRuleName).map(ruleName => {
                        const cssObject = cssObjectByRuleName[ruleName];
                        if (!cssObject.label) {
                            cssObject.label = `${name !== undefined ? `${name}-` : ""}${ruleName}`;
                        }
                        return [
                            ruleName,
                            `${css(cssObject)}${typeGuard(ruleName, ruleName in refClassesCache)
                                ? ` ${refClassesCache[ruleName]}`
                                : ""}`
                        ];
                    }));
                    objectKeys(refClassesCache).forEach(ruleName => {
                        if (ruleName in classes) {
                            return;
                        }
                        classes[ruleName] =
                            refClassesCache[ruleName];
                    });
                    return classes;
                }, [cache, css, cx, theme, getDependencyArrayRef(params)]);
                const propsClasses = styleOverrides?.props.classes;
                classes = useMemo(() => mergeClasses(classes, propsClasses, cx), [classes, getDependencyArrayRef(propsClasses), cx]);
                {
                    let cssObjectByRuleNameOrGetCssObjectByRuleName = undefined;
                    try {
                        cssObjectByRuleNameOrGetCssObjectByRuleName =
                            name !== undefined
                                ? theme.components?.[name]
                                    ?.styleOverrides
                                : undefined;
                        // eslint-disable-next-line no-empty
                    }
                    catch { }
                    const themeClasses = useMemo(() => {
                        if (!cssObjectByRuleNameOrGetCssObjectByRuleName) {
                            return undefined;
                        }
                        const themeClasses = {};
                        for (const ruleName in cssObjectByRuleNameOrGetCssObjectByRuleName) {
                            const cssObjectOrGetCssObject = cssObjectByRuleNameOrGetCssObjectByRuleName[ruleName];
                            if (!(cssObjectOrGetCssObject instanceof Object)) {
                                continue;
                            }
                            themeClasses[ruleName] = css(typeof cssObjectOrGetCssObject === "function"
                                ? cssObjectOrGetCssObject({
                                    theme,
                                    "ownerState": styleOverrides?.ownerState,
                                    ...styleOverrides?.props
                                })
                                : cssObjectOrGetCssObject);
                        }
                        return themeClasses;
                    }, [
                        cssObjectByRuleNameOrGetCssObjectByRuleName ===
                            undefined
                            ? undefined
                            : JSON.stringify(cssObjectByRuleNameOrGetCssObjectByRuleName),
                        getDependencyArrayRef(styleOverrides?.props),
                        getDependencyArrayRef(styleOverrides?.ownerState),
                        css
                    ]);
                    classes = useMemo(() => mergeClasses(classes, themeClasses, cx), [classes, themeClasses, cx]);
                }
                return {
                    classes,
                    theme,
                    css,
                    cx
                };
            };
        };
    }
    function useStyles() {
        const theme = useTheme();
        const { css, cx } = useCssAndCx();
        return { theme, css, cx };
    }
    return { makeStyles, useStyles };
}
const reactContext = createContext(undefined);
function useCacheProvidedByProvider() {
    const cacheExplicitlyProvidedForTss = useContext(reactContext);
    return cacheExplicitlyProvidedForTss;
}
export function TssCacheProvider(props) {
    const { children, value } = props;
    return (React.createElement(reactContext.Provider, { value: value }, children));
}
