{
  "name": "@regscout/core",
  "version": "1.13.2",
  "main": "App.jsx",
  "author": "BreachRx",
  "description": "",
  "private": true,
  "peerDependencies": {
    "react": ">=17.x",
    "react-dom": ">=17.x"
  },
  "devDependencies": {
    "@apollo/client": "^3.11.8",
    "@emotion/react": "^11.10.5",
    "@emotion/styled": "^11.10.5",
    "@fontsource/oswald": "^4.5.12",
    "@fontsource/roboto": "^4.5.8",
    "@mui/icons-material": "^5.16.11",
    "@mui/material": "^5.16.11",
    "dayjs": "^1.11.7",
    "dompurify": "^3.1.7",
    "graphql": "^16.8.1",
    "js-cookie": "^3.0.1",
    "postcss": "^8.4.32",
    "postcss-modules": "^4.3.1",
    "prop-types": "^15.8.1",
    "random": "^4.1.0",
    "react-hook-form": "^7.43.1",
    "react-router": "^6.8.1",
    "react-router-dom": "^6.8.1",
    "react-simple-maps": "^4.0.0-beta",
    "suneditor": "^2.44.10",
    "suneditor-react": "^3.4.1",
    "tss-react": "^4.6.0",
    "underscore": "^1.13.6"
  }
}
