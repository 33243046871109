import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../../../resources/theme";

export const useDetailStyles = makeStyles()((theme) => ({
  detailContainer: {
    flexGrow: 1,
    bgcolor: "background.default",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0px",
    },
  },
  paperWrapper: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(48),
    },
  },
  lastUpdated: {
    color: theme.palette.primary.main,
  },
  detailWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    minHeight: "100vh",
  },
  sidebarLogoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 80,
  },
  controlsItemTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainWrapper: {
    flexGrow: 1,
    overflow: "auto",
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  actionLinks: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1.25rem",
    },
  },
  breachRxLinks: {
    listStyle: "none",
  },
  notificationsWrapper: {
    borderBottom: "none",
    display: "flex",
    flexDirection: "column",
  },
  notificationTitle: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  notificationBlock: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: "none",
    display: "flex",
  },
  notificationBlockTitle: {
    width: "125px",
  },
  notificationBlockText: {
    maxWidth: "calc(100% - 155px)",
    position: "relative",
  },
  redBorderBlock: {
    border: "3px solid red",
    boxShadow: "0px 1px 4px darkred",
    margin: `0px ${theme.spacing(-3)}`,
    padding: `0px ${theme.spacing(3)}`,
  },
  standoutDetailBlock: {
    backgroundColor:
      theme.palette.mode === "dark" ? "rgb(79, 79, 79)" : "rgb(233, 233, 233)",
    margin: `0px ${theme.spacing(-3)}`,
    padding: `0px ${theme.spacing(3)}`,
  },
  upgradeContainer: {
    filter: "blur(4px)",
    userSelect: "none",
    cursor: "default",
  },
  sourceBlock: {
    "&> span > .sun-editor > .se-container .se-wrapper .se-wrapper-inner > * > a":
      {
        color: theme.palette.mode === "dark" ? "#0079ff" : undefined,
      },
  },
}));
